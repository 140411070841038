import { Container, Link, Typography } from '@material-ui/core';
import React from 'react';
import { SocialLinks } from 'src/assets/data';
import { ReactComponent as BBCLogo } from 'src/assets/logos/Billionaire_club_logo.svg';
// import { MintBatsButton } from 'src/components/mint-bats-btn';
import { useStyles } from './bottom.styles';

export const Bottom = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <BBCLogo className={classes.logo} />

      <div className={classes.buttonContainer}>{/* <MintBatsButton /> */}</div>

      <Typography
        variant="h4"
        component="h4"
        color="textPrimary"
        align="center"
      >
        Follow Billionaire Bats Club on{' '}
        <Link
          href={SocialLinks.Twitter}
          rel="noopener noreferrer nofollow"
          target="_blank"
          underline="none"
          color="secondary"
        >
          Twitter
        </Link>{' '}
        or{' '}
        <Link
          href={SocialLinks.Discord}
          rel="noopener noreferrer nofollow"
          target="_blank"
          underline="none"
          color="secondary"
        >
          Discord
        </Link>
      </Typography>
    </Container>
  );
};
