import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
// import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import React from 'react';
import BBCLogo from 'src/assets/logos/SimpleBBC.png';
import { ReactComponent as GifBackgroundSVG } from 'src/assets/svgs/Path_212.svg';
// import { MintBatsButton } from 'src/components/mint-bats-btn';
import { Sparkles } from 'src/components/sparkles';
import { useStyles } from './top.styles';

export const Top = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Container maxWidth="lg" className={classes.contentContainer}>
        <img
          className={classes.bbcLogo}
          src={BBCLogo}
          draggable={false}
          alt="BBC logo"
        />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5} className={classes.leftContainer}>
            {/* <WalletMultiButton /> */}
            <Typography
              color="textPrimary"
              component="h1"
              variant="h1"
              className={classes.title}
            >
              Billionaire Bats Club
            </Typography>
            <Sparkles className={classes.sparkleContainer} />
            <div className={classes.bottomHeader}>
              <Typography color="textPrimary" component="h3" variant="h3">
                Focusing on Utility for the Metaverse & Real World.
              </Typography>

              <Button
                size="large"
                variant="contained"
                color="secondary"
                style={{
                  marginTop: '2rem'
                }}
                disabled={true}
              >
                Mint A Bat!
              </Button>

              {/* <MintBatsButton className={classes.mintButton} /> */}
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className={classes.gifContainer}>
              <GifBackgroundSVG className={classes.gifBackground} />
              <img
                alt="gif of bats"
                className={classes.gif}
                draggable={false}
                src={
                  'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286971/BillionaireBats/BAT.gif'
                }
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
