import { Container, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { ReactComponent as ArrowRight } from 'src/assets/svgs/ArrowRight.svg';
// import { MintBatsButton } from 'src/components/mint-bats-btn';
import { Sparkles } from 'src/components/sparkles';
import { useStyles } from './story.styles';

export const Story = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Sparkles className={classes.sparkleContainer} />
      <ArrowRight className={classes.arrowRight} />
      <div className={classes.innerContainer}>
        <div className={classes.textContainer}>
          {/* <img src="" alt="Our Story Logo"/> */}
          <Typography
            component="h2"
            variant="h2"
            align="center"
            className={classes.title}
          >
            Our Story
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            The Billionaire Bats Club launched on October 30th, 2021, with a
            strong focus on utility and the metaverse. Our team consists of
            passionate Solana advocates from the UK and USA.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            style={{ marginBottom: theme.spacing(3) }}
          >
            We introduced 7,500 unique bats and offer a 50% royalty share to all
            holders as a token of appreciation. Additionally, an airdrop is
            scheduled for all holders.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            style={{ marginBottom: theme.spacing(3) }}
          >
            Many projects overlook the true potential of NFTs and the
            opportunities they create. That's why we chose to launch on Solana—a
            newer blockchain with lower transaction fees and greater potential
            for growth. Stay tuned for updates, as we will be gradually rolling
            out exciting utilities for all Billionaire Bats Club holders. You
            can explore our roadmap at the bottom of this page!
          </Typography>
          {/* <MintBatsButton /> */}
        </div>
      </div>
    </Container>
  );
};
